import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./YoutubeVideoSlider.css";
import { getImage } from '../../utils/getImage';

const rocket = getImage('/whatsapp-pricing/Rocket.png');

const YoutubeVideoSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const videos = [
    { id: "0BIScxjyEMk", title: "Chat Automation", description: "Experience Seamless Chat Automation with Anantya.ai!" },
    { id: "daa1bYEfv9c", title: "SMS Fallback", description: "Ensure Every Message Gets Through with Anantya.ai’s SMS Fallback!" },
    { id: "-EnTeJddC80", title: "WhatsApp Broadcasting!", description: "Boost Engagement Effortlessly: Unlock the Power of WhatsApp Broadcasting!" },
    { id: "dlBvpU-t1r8", title: "Feature Highlights", description: "Anantya.ai: Sneak Peak into our Feature Highlights" },
    { id: "sPd-b2SobbU", title: "WhatsApp Business API", description: "Get to know the power of WhatsApp Business API with Anantya.ai" },
    { id: "ozBMhE9o7nU", title: "Affiliate Partner", description: "The Best Affiliate Program to Start Today with No Money | Join Anantya.ai.." },
  ];

  return (
    <div className="video-slider-container mb-5 pb-5">
      <h2 className="heading-gradient">
        Watch Anantya.ai in Action <span><img src={rocket} className="img-fluid rocket" alt="action" /></span>
      </h2>
      <p>Discover how Anantya.ai is transforming customer engagement with <br/> smart, easy-to-use solutions.</p>
      <div className="bg-white py-2">
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={`video-${index}`} className="video-card mt-3">
              <div className="video-wrapper">
                <iframe
                  width="100%"
                  height="200px"
                  src={`https://www.youtube.com/embed/${video.id}`}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <a
                  className="overlay"
                  href={`https://www.youtube.com/watch?v=${video.id}&autoplay=1`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">Open video in YouTube</span>
                </a>
              </div>
              <div className="video-info text-start ">
                <h4 className="video-title">{video.title}</h4>
                <p className="video-description">{video.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default YoutubeVideoSlider;
