import React from 'react';
import './CardsSection.css';

import { getImage } from '../../utils/getImage';  // Correct path to utility
const cardImg1 = getImage('platform-1.png');
const cardImg2 = getImage('platform-2.png');
const cardImg3 = getImage('platform-3.png');
const cardImg4 = getImage('platform-4.png');


const CardsSection = () => {
  return (
    <div className="platform-cards-container text-white">
    {/* First Row */}
    <div className="platform-row platform-first-row">
      {/* Left (70%) */}
      <div className="platform-col-70">
        <div className="platform-card platform-whatsapp-payment position-relative">
          <h4 className='text-white px-4'>WhatsApp Payment</h4>
          <p className='px-4'>No more switching apps – Make payments as easy as texting with WhatsApp Payments! Safe, seamless, and instant.</p>
         <img src={cardImg1} className='img-fluid   position-absolute bottom-0 start-50 translate-middle-x w-100' alt="WhatsApp Payment" />
        </div>
      </div>
      {/* Right (30%) */}
      <div className="platform-col-30">
        <div className="platform-card platform-customer-support text-center">
          <h4 className='text-white px-4'>Best Customer Support</h4>
          <p className='px-4'>Get the help you need, when you need it!  Let your customers experience top-tier support with WhatsApp – fast, personal, and always <available className=""></available></p>
          <a type='button' href='/contact' className="platform-btn">Contact Us</a>
          <img src={cardImg2} className='img-fluid d-block mx-auto w-75' alt="Best Customer Support" />
        </div>
      </div>
    </div>

    {/* Second Row */}
    <div className="platform-row platform-second-row">
      {/* Left (30%) */}
      <div className="platform-col-30">
        <div className="platform-card platform-whatsapp-forms ">
          <h4 className='text-white px-4'>WhatsApp Forms</h4>
          <p className='px-4'>Collect feedback, surveys, and more with WhatsApp Forms – quick, convenient, and secure</p>
          <img src={cardImg3} className='img-fluid w-75 mx-auto text-center d-block' alt="WhatsApp Forms" />
        </div>
      </div>
      {/* Right (70%) */}
      <div className="platform-col-70">
        <div className="platform-card platform-whatsapp-carousels position-relative">
          <h4 className='text-white px-4'>WhatsApp Carousels</h4>
          <p className='px-4'>Highlight your products in a dynamic, interactive way with WhatsApp Carousel – swipe and shop instantly</p>
          <img src={cardImg4} className='img-fluid position-absolute bottom-0 start-50 translate-middle-x w-100' alt="WhatsApp Carousels" />
        </div>
      </div>
    </div>
    </div>
  );
};

export default CardsSection;
