import React, { useState } from "react";
import "./TabSection.css"; // Ensure this file contains appropriate styling
import { getImage } from "../../utils/getImage"; // Adjust the path if needed

const tabs1 = getImage("/whatsapp-pricing/tab-1.png");
const tabs2 = getImage("/whatsapp-pricing/tab-2.png");
const tabs3 = getImage("/whatsapp-pricing/tabs-3.png");
const tabs4 = getImage("/whatsapp-pricing/tabs-4.png");

const TabSection = () => {
  const [activeTab, setActiveTab] = useState("payment");

  const tabContent = {
    payment: {
      tabButtonText: "Make Payments",
      heading: "Make Payments a Breeze with WhatsApp",
      description:
        "No more hassle—let your customers pay effortlessly through WhatsApp! With secure and quick transactions, you can offer a seamless shopping experience that builds trust and boosts loyalty.",
      buttonText: "Activate Payments Today",
      image: tabs1,
    },
    forms: {
      tabButtonText: "WhatsApp Forms",
      heading: "Gather Insights Like Never Before with WhatsApp Forms",
      description:
        "Engage smarter, not harder! WhatsApp Forms let you collect feedback, leads, and customer preferences in an interactive way, making the process enjoyable for your audience and valuable for your business.",
      buttonText: "Create Your Forms",
      image: tabs2,
    },
    carousels: {
      tabButtonText: "WhatsApp Carousels",
      heading: "Sell Smarter with WhatsApp’s Carousel Feature",
      description:
        "Turn your WhatsApp chats into a visual shopping experience! Showcase multiple products or services in style, letting your customers swipe, explore, and choose—all in one place.",
      buttonText: "Start Showcasing Now",
      image: tabs3,
    },
    ads: {
      tabButtonText: "Click to WhatsApp Ads",
      heading: "Spark Instant Connections with Click-to-WhatsApp Ads",
      description:
        "Stop wasting clicks! With CTWA ads, take your customers straight to the conversation. Build relationships, answer queries, and close deals—all in the comfort of a chat.",
      buttonText: "Get Started with CTWA",
      image: tabs4,
    },
  };

  return (
    <div className="unique-tab-section py-5">
      <div className="container">
        <h2 className="heading-gradient">Incredible Platform Capabilities</h2>
        <p>
          Streamline, Engage, Notify: Your All-In-One Platform For Effective Business
          <br /> Communication
        </p>
        <div className="tabs d-flex justify-content-center mb-4">
          {Object.keys(tabContent).map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tabContent[tab].tabButtonText}
            </button>
          ))}
        </div>

        <div className="tab-content row align-items-center">
          <div className="image-section col-md-6">
            <img
              src={tabContent[activeTab].image}
              className="img-fluid"
              alt={tabContent[activeTab].heading}
            />
          </div>
          <div
            className={`content text-justify text-start px-5 col-md-6 ${activeTab}`}
          >
            <h3>{tabContent[activeTab].heading}</h3>
            <p>{tabContent[activeTab].description}</p>
            <button
  type="button"
  className="btn btn-primary gr-btn"
  onClick={(e) => {
    e.preventDefault(); // Default behavior rokna
    const targetElement = document.getElementById("myStickyForm");
    if (targetElement) {
      const offset = targetElement.getBoundingClientRect().top + window.scrollY - 50; // 100px less scroll
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  }}
>
  {tabContent[activeTab].buttonText}
</button>


          </div>
        </div>
      </div>
    </div>
  );
};

export default TabSection;
