import React, { useState } from 'react';
import './form.css';

const StartedForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    url: '',
  });

  const [statusMessage, setStatusMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.message) {
      setStatusMessage('Please fill in all required fields.');
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setStatusMessage('Please enter a valid email address.');
      return false;
    }

    if (formData.url && !/^(https?:\/\/)?(www\.)?.+\..+$/.test(formData.url)) {
      setStatusMessage('Please enter a valid URL.');
      return false;
    }

    if (!isTermsAccepted) {
      setStatusMessage('Please accept the terms and conditions.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('');

    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const response = await fetch('https://formspree.io/f/mdorezev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
          url: formData.url,
        }),
      });

      if (response.ok) {
        setStatusMessage('Form submitted successfully!');
        setFormData({ name: '', email: '', phone: '', message: '', url: '' });
        setIsTermsAccepted(false);
      } else {
        setStatusMessage('There was an error submitting the form.');
      }
    } catch (error) {
      setStatusMessage('An unexpected error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      id="startedForm"
      className="form mx-lg-0 mx-md-0 px-3 mt-lg-0 mt-md-0 mt-4"
      onSubmit={handleSubmit}
    >
      <div className="px-md-5 px-3 contact">
        <h3 className="text-center text-black">Fill The Form Below</h3>
        <p className="text-center text-danger fs-12px mb-30">
          The field is required mark as *
        </p>

        <div className="form-group mb-20">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Name *"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email Address *"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20">
          <input
            type="tel"
            name="phone"
            className="form-control"
            placeholder="Phone Number (optional)"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-20">
          <input
            type="text"
            name="message"
            className="form-control"
            placeholder="Name of Organisation *"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20">
          <input
            type="url"
            name="url"
            className="form-control"
            placeholder="Website URL (optional)"
            value={formData.url}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-20 d-flex align-items-center">
          <input
            type="checkbox" className='custom-checkbox ms-5'
            name="terms"
            id="terms"
            checked={isTermsAccepted}
            onChange={(e) => setIsTermsAccepted(e.target.checked)}
          />
          <label htmlFor="terms" className="small-label ms-4 mt-1">
            I accept all the terms and conditions
          </label>
        </div>
        <div className="text-center mt-3">
          <input
            type="submit"
            value={isSubmitting ? 'Submitting...' : 'Send Your Request'}
            className="btn bg-green text-light fs-12px w-auto w-md-75"
            disabled={isSubmitting}
          />
        </div>
        {statusMessage && <p className="text-center mt-3">{statusMessage}</p>}
      </div>
    </form>
  );
};

export default StartedForm;
