import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './IntegrationsSection2.css';
import { getImage } from '../../utils/getImage';

// Fetch images dynamically
const images = [
  getImage('/whatsapp-pricing/integration/int-1.png'),
  getImage('/whatsapp-pricing/integration/int-2.png'),
  getImage('/whatsapp-pricing/integration/int-3.png'),
  getImage('/whatsapp-pricing/integration/int-4.png'),
  getImage('/whatsapp-pricing/integration/int-5.png'),
  getImage('/whatsapp-pricing/integration/int-6.png'),
  getImage('/whatsapp-pricing/integration/int-7.png'),
  getImage('/whatsapp-pricing/integration/int-8.png'),
  getImage('/whatsapp-pricing/integration/int-9.png'),
  getImage('/whatsapp-pricing/integration/int-10.png'),
  getImage('/whatsapp-pricing/integration/int-11.png'),
  getImage('/whatsapp-pricing/integration/int-12.png')
];

const IntegrationsSection2 = () => {
  return (
    <section className="py-5 mb-5 IntegrationsSection d-block d-md-none  mobile">
      <Container>

        {/* 🔹 Row 1 (6 Boxes) */}
        {/* 🔹 Row 1 (6 Boxes) */}
        <Row className="mb-3 justify-content-center">
          {Array(6).fill(null).map((_, i) => (
            <Col key={i} xs={2} md={2}>
              <div className="integration-box">
                {i % 2 === 0 && images[i / 2] ? (  // सिर्फ odd positions (1st, 3rd, 5th) पर image दिखाएं
                  <img src={images[i / 2]} alt="Integration" className="integration-image" />
                ) : null}
              </div>
            </Col>
          ))}
        </Row>


        {/* 🔹 Row 2 (6 Boxes) */}
        <Row className="mb-3 justify-content-center">
          {Array(6).fill(null).map((_, i) => (
            <Col key={i} xs={2} md={2}>
              <div className="integration-box">
                {i % 2 !== 0 && images[Math.floor(i / 2)] ? (  // सिर्फ even index (2nd, 4th, 6th) पर image लगाएं
                  <img src={images[Math.floor(i / 4)]} alt="Integration" className="integration-image" />
                ) : null}
              </div>
            </Col>
          ))}
        </Row>


        {/* 🔸 Main Content Box (Full Width) */}
        <Row className="mb-3">
          <Col xs={12} className="text-center">
            <div className="content-box py-4">
              <h2 className=' h6'>Seamless Integration with your Tech Stack</h2>
              <a href="/integration" className="btn btn-grad" target="_blank" rel="noopener noreferrer">
                <u>See all Integrations</u>
              </a>
            </div>
          </Col>
        </Row>

        {/* 🔹 Row 3 (Odd Positions: 1, 3, 5) */}
        <Row className="mb-3 justify-content-center">
          {Array(6).fill(null).map((_, i) => (
            <Col key={i} xs={2} md={2}>
              <div className="integration-box">
                {i % 2 === 0 && images[Math.floor(i / 2)] ? ( // सिर्फ Odd positions (index 0, 2, 4) पर image
                  <img src={images[Math.floor(i / 2)]} alt="Integration" className="integration-image" />
                ) : null}
              </div>
            </Col>
          ))}
        </Row>

        {/* 🔹 Row 4 (Even Positions: 2, 4, 6) */}
        <Row className="mb-3 justify-content-center">
          {Array(6).fill(null).map((_, i) => (
            <Col key={i} xs={2} md={2}>
              <div className="integration-box">
                {i % 2 !== 0 && images[Math.floor(i / 2)] ? ( // सिर्फ Even positions (index 1, 3, 5) पर image
                  <img src={images[Math.floor(i / 2)]} alt="Integration" className="integration-image" />
                ) : null}
              </div>
            </Col>
          ))}
        </Row>


      </Container>
    </section>
  );
};

export default IntegrationsSection2;
