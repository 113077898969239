import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AutoScrollCarousel.css'; // Custom styling
import { getImage } from "../../utils/getImage"; // Adjust the path as needed

const items = [
  { img: getImage('/whatsapp-pricing/Speak.png'), text: "Free Website Widget", link: "/whatsapp-widget-generator" },
  { img: getImage('/whatsapp-pricing/QR code.png'), text: "Free QR Code & Custom Link", link: "/generate-whatsapp-link" },
  { img: getImage('/whatsapp-pricing/blue-tick.png'), text: "Free Blue Tick Verification", link: "/contact" },
  { img: getImage('/whatsapp-pricing/automated-chatbot-support.png'), text: "Automated Chatbot Support", link: "/whatsapp-business-api" },
  { img: getImage('/whatsapp-pricing/seamless-crm-integration.png'), text: "Seamless CRM Integration", link: "/whatsapp-business-api" },
  { img: getImage('/whatsapp-pricing/multi-agent-access.png'), text: "Multi-Agent Access", link: "/whatsapp-business-api" },
  { img: getImage('/whatsapp-pricing/broadcast-bulk-messaging.png'), text: "Broadcast & Bulk Messaging", link: "/whatsapp-business-api" },
  { img: getImage('/whatsapp-pricing/automated-chatbot-support.png'), text: "Rich Media Messaging", link: "/whatsapp-business-api" },
  { img: getImage('/whatsapp-pricing/advanced-analytics-reports.png'), text: "Advanced Analytics & Reports", link: "/whatsapp-business-api" },
];

const AutoScrollCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Continuous smooth scrolling
    cssEase: "linear",
    pauseOnHover: false,
    swipeToSlide: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } }, // Tablet
      { breakpoint: 768, settings: { slidesToShow: 2 } }, // Large Phones
      { breakpoint: 576, settings: { slidesToShow: 1 } }, // Small Mobile
    ],
  };

  return (
    <div className="carousel-container">
      <h2 className='why_heading  text-center'>Hassle-free No-cost Onboarding & Support</h2>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="carousel-item py-4">
            <img src={item.img} alt={item.text} className="carousel-img" />
            <a href={item.link} target="_blank" rel="noopener noreferrer" className="carousel-link">
              {item.text}
            </a>
          </div>

        ))}
      </Slider>
    </div>
  );
};

export default AutoScrollCarousel;
