import React, { useState } from 'react';
import { Container, Tab, Tabs, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TabSection2.css';
import { getImage } from '../../utils/getImage';  // Correct path to utility

const tabImg1 = getImage('/tabimg-1.png');
const tabImg2 = getImage('/tabimg-2.png');
const tabImg3 = getImage('/tabimg-3.png');
const tabImg4 = getImage('/tabimg-4.png');
const tabImg5 = getImage('/tabimg-5.png');
const icon1 = getImage('/icons/Warning.png');
const icon2 = getImage('/icons/Gear.png');
const icon3 = getImage('/icons/Videocall.png');
const icon4 = getImage('/icons/Chatbot.png');
const icon5 = getImage('/icons/Promote.png');

function TabsSection2() {
    const [key, setKey] = useState('tab1');

    const handleSelect = (k) => setKey(k);

    const handlePrev = () => {
        const currentIndex = parseInt(key.replace('tab', ''), 10);
        if (currentIndex > 1) setKey(`tab${currentIndex - 1}`);
    };

    const handleNext = () => {
        const currentIndex = parseInt(key.replace('tab', ''), 10);
        if (currentIndex < 5) setKey(`tab${currentIndex + 1}`);
    };

    const renderTabTitle = (icon, text) => (
        <span className="d-flex align-items-center">
            <img src={icon} alt="" className="me-2 tab-icon" />
            {text}
        </span>
    );
    const scrollToForm = () => {
        const formElement = document.getElementById("myStickyForm");
        if (formElement) {
            formElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };
    return (
        <Container className="TabSection2 m-5">
            <Tabs activeKey={key} onSelect={handleSelect} className="pb-4 mb-3 justify-content-center border-0 text-white">
                <Tab eventKey="tab1" title={renderTabTitle(icon1, 'Broadcast')}>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <div className="tab-content">
                                <div class=" my-auto text-lg-start text-center">

                                    <h3 class="h4">Broadcast Unlimited Promotional Messages Without Getting Blocked</h3>
                                    <p>Increase sales by sending messages about discounts, festival promotions, product launches, and season-end sales. Boost your business with effective Communication</p>
                                    <div class="d-flex text-center mobile-center">
                                        <button class="btn btn-tab me-2 rounded-pill" onClick={scrollToForm}>Learn More</button>
                                        <a href="/whatsapp-broadcasting" target="_blank" rel="noopener noreferrer">
                                            <button class="btn btn-tab2 rounded-pill">Explore</button>
                                        </a>

                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-4 button-wrapper  mobile-center">
                                        <Button className="pre-btn me-3" onClick={handlePrev} disabled={key === 'tab1'}>
                                            <i className="fas fa-long-arrow-left colorfa_1 my-auto"></i>
                                        </Button>
                                        <Button className="next-btn" onClick={handleNext} disabled={key === 'tab5'}>
                                            <i className="fas fa-long-arrow-right colorfa_1 my-auto"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={tabImg1} alt="Tab 1" className="img-fluid" />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="tab2" title={renderTabTitle(icon2, 'Integration')}>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <div className="tab-content">
                                <div class=" my-auto text-lg-start text-center">

                                    <h3 class="h4">Automate Notifications via Integration</h3>
                                    <p>Automate notifications for orders, deliveries, payments, abandoned cart reminders, and more on WhatsApp.
                                        Once Automated Notifications are activated, businesses can effortlessly send automated WhatsApp notifications for every order.</p>
                                    <div class="mt-4 d-flex text-center mobile-center">
                                        <button class="btn btn-tab me-2 rounded-pill" onClick={scrollToForm}>Learn More</button>
                                        <a href="/integration" target="_blank" rel="noopener noreferrer">
                                            <button class="btn btn-tab2 rounded-pill">Explore</button>
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-4 button-wrapper  mobile-center">
                                        <Button className="pre-btn me-3" onClick={handlePrev} disabled={key === 'tab1'}>
                                            <i className="fas fa-long-arrow-left colorfa_1 my-auto"></i>
                                        </Button>
                                        <Button className="next-btn" onClick={handleNext} disabled={key === 'tab5'}>
                                            <i className="fas fa-long-arrow-right colorfa_1 my-auto"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={tabImg2} alt="Tab 2" className="img-fluid" />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="tab3" title={renderTabTitle(icon3, 'Live Chat')}>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <div className="tab-content">
                                <div class=" my-auto text-lg-start text-center">

                                    <h3 class="h4">Provide Live Chat Support on Multiple Devices</h3>
                                    <p>Use WhatsApp Business API to handle customer support, boost sales, and grow your business on WhatsApp. With the API, offer Live Chat on as many devices as you need, all with the same phone number.</p>
                                    <div class="mt-4 d-flex text-center mobile-center">
                                        <button class="btn btn-tab me-2 rounded-pill" onClick={scrollToForm}>Learn More</button>
                                        <a href="/live-agent-support" target="_blank" rel="noopener noreferrer">
                                            <button class="btn btn-tab2 rounded-pill">Explore</button>
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-4 button-wrapper mobile-center">
                                        <Button className="pre-btn me-3" onClick={handlePrev} disabled={key === 'tab1'}>
                                            <i className="fas fa-long-arrow-left colorfa_1 my-auto"></i>
                                        </Button>
                                        <Button className="next-btn" onClick={handleNext} disabled={key === 'tab5'}>
                                            <i className="fas fa-long-arrow-right colorfa_1 my-auto"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={tabImg3} alt="Tab 3" className="img-fluid" />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="tab4" title={renderTabTitle(icon4, 'AI Chatbot')}>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <div className="tab-content">
                                <div class=" my-auto text-lg-start text-center">

                                    <h3 class="h4">Integrate AI Chatbot to Automate Support & Sales</h3>
                                    <p>This advanced Chatbot is designed to efficiently handle customer queries, drive sales, and collect valuable user information.

                                        With capabilities like a dedicated customer support agent, it ensures a seamless and human-like experience for your customers.</p>
                                    <div class="mt-4 mobile-center d-flex text-center">
                                        <button class="btn btn-tab me-2 rounded-pill" onClick={scrollToForm}>Learn More</button>
                                        <a href="/automated-chatbot" target="_blank" rel="noopener noreferrer">
                                            <button class="btn btn-tab2 rounded-pill">Explore</button>
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-4 button-wrapper mobile-center">
                                        <Button className="pre-btn me-3" onClick={handlePrev} disabled={key === 'tab1'}>
                                            <i className="fas fa-long-arrow-left colorfa_1 my-auto"></i>
                                        </Button>
                                        <Button className="next-btn" onClick={handleNext} disabled={key === 'tab5'}>
                                            <i className="fas fa-long-arrow-right colorfa_1 my-auto"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={tabImg4} alt="Tab 4" className="img-fluid" />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="tab5" title={renderTabTitle(icon5, 'WhatsApp Ads')}>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <div className="tab-content">
                                <div class=" my-auto text-lg-start text-center">

                                    <h3 class="h4">Supercharge Your Leads with Click to WhatsApp Ads!</h3>
                                    <p>Seamlessly organize all your leads according to ad campaigns, and design chatbot flows tailored to ads or retarget your audience via broadcast messages.

                                        Launch impactful ads on Facebook and Instagram that directly connect users to WhatsApp. Achieve a 5X increase in lead generation and witness 2-3 times more conversions instantly!</p>
                                    <div class="mt-4 mobile-center d-flex text-center">
                                        <button class="btn btn-tab me-2 rounded-pill" onClick={scrollToForm}>Learn More</button>
                                        <a href="/click-to-whatsapp-ads" target="_blank" rel="noopener noreferrer">
                                            <button class="btn btn-tab2 rounded-pill">Explore</button>
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-4 button-wrapper mobile-center">
                                        <Button className="pre-btn me-3" onClick={handlePrev} disabled={key === 'tab1'}>
                                            <i className="fas fa-long-arrow-left colorfa_1 my-auto"></i>
                                        </Button>
                                        <Button className="next-btn" onClick={handleNext} disabled={key === 'tab5'}>
                                            <i className="fas fa-long-arrow-right colorfa_1 my-auto"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={tabImg5} alt="Tab 5" className="img-fluid" />
                        </Col>
                    </Row>
                </Tab>
            </Tabs>

        </Container>

    );
}

export default TabsSection2;
