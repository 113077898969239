import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ScrollToTopButton from "../components/common/ScrollToTopButton";
import WhatsAppWidget from "../components/common/WhatsAppWidget";

const DigitalServices = () => {
  return (
    <div>
     <Container className="py-5">
      <h2 className="text-center mb-4">Our Digital Services</h2>
      <Row>
        <Col md={6} lg={4}>
          <Card className="mb-4 shadow-sm h-100">
            <Card.Body>
              <Card.Title>Web Development</Card.Title>
              <Card.Text>
                We create modern, responsive, and user-friendly websites to enhance your online presence.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card className="mb-4 shadow-sm h-100">
            <Card.Body>
              <Card.Title>Static Websites</Card.Title>
              <Card.Text>
                Fast and lightweight websites, perfect for portfolios and small businesses.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card className="mb-4 shadow-sm h-100">
            <Card.Body>
              <Card.Title>Dynamic Websites</Card.Title>
              <Card.Text>
                Feature-rich and interactive websites powered by the latest technologies.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <ScrollToTopButton />
    <WhatsAppWidget />
    </div>
   
  );
};

export default DigitalServices;
