import React, { useState } from "react";
import "./HeroSection.css";
import { getImage } from "../../utils/getImage"; // Utility function for dynamic image fetching
import demoVideo from "../../assets/videos/demo-video.mp4";
const meta = getImage("/whatsapp-pricing/Meta.png");
const playIcon = getImage("/whatsapp-pricing/play_icon.svg");
const demoVideoThumbnail = getImage("/whatsapp-pricing/demo-video.png");
const homepageframe = getImage("homepagegraphices.png");
const curveImage = getImage("/whatsapp-pricing/curve.png");
const HeroSection = () => {
  const [isPlaying, setIsPlaying] = useState(false); // State to control video playback
    const handlePlay = () => {
    setIsPlaying(true);
  };
  const scrollToForm = () => {
    const formElement = document.getElementById("myStickyForm");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const scrollToPrice = () => {
    const formElement = document.getElementById("target-section");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <div className="hero-section">
        <div className="hero-banner">
          <div className="hero-top">
            <span className="badge">
              Powered by Official WhatsApp Business API &nbsp;
              <svg
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
              >
                <path
                  d="m.76 21.24 1.412-5.12A10.324 10.324 0 0 1 .76 10.93C.76 5.35 5.35.76 10.964.76 16.58.76 21.24 5.35 21.24 10.93c0 5.578-4.661 10.31-10.276 10.31-1.765 0-3.46-.565-4.978-1.413L.76 21.24Z"
                  fill="#EDEDED"
                ></path>
                <path
                  d="m6.268 17.991.318.177c1.307.812 2.825 1.306 4.414 1.306 4.626 0 8.474-3.848 8.474-8.545 0-4.696-3.848-8.404-8.51-8.404-4.66 0-8.439 3.743-8.439 8.404 0 1.624.46 3.213 1.307 4.555l.212.318-.812 2.966 3.036-.777Z"
                  fill="#25D366"
                ></path>
                <path
                  d="m8.245 6.198-.671-.036a.802.802 0 0 0-.565.212c-.318.283-.848.812-.989 1.519-.247 1.059.141 2.33 1.06 3.601.918 1.271 2.683 3.32 5.79 4.202.989.283 1.766.106 2.402-.282.494-.318.812-.812.918-1.342l.105-.494a.355.355 0 0 0-.176-.389l-2.225-1.024a.337.337 0 0 0-.423.106l-.883 1.13a.275.275 0 0 1-.283.07c-.6-.211-2.613-1.059-3.707-3.177-.036-.106-.036-.212.035-.283l.848-.953c.07-.106.105-.247.07-.353L8.527 6.41a.308.308 0 0 0-.282-.212Z"
                  fill="#FEFEFE"
                ></path>
              </svg>
            </span>
            <a href="https://www.facebook.com/business/partner-directory/search?solution_type=messaging&platforms=whatsapp&id=4336810659772608&section=overview" className="meta-link" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
              <span>
                <span>
                  <img
                    src={meta}
                    className="img-fluid meta_logo text-white"
                    alt="Meta Logo"
                  />
                </span>
                <u>Meta <small> Tech Partner </small></u>
              </span>
            </a>
           </div>
          <h1 className="hero-title">
            Are you Ready to Transform your <br /> Customer Service with AI?
          </h1>
          <p className="hero-subtitle">
            Transform every connection into growth and redefine communication for
            businesses <br />
            with seamless messaging powered by AI.
          </p>
          <div className="hero-buttons">
           {/* <a type="button" className="btn-get" href="https://calendly.com/info-w0m/30min?month=2025-01" target="_blank" rel="noopener noreferrer"> Get Started →</a> */}
            <button onClick={scrollToPrice} className="btn-book">Get Started →</button>
            <button onClick={scrollToForm} className="btn-book">Book a Demo →</button>
          </div>
          <p className="hero-note text-white">
            (Get started for free + Access all the essentials with our free plan)
          </p>
          <div className="hero-icon-network d-none">
            <img src={homepageframe} className="img-fluid" alt="homepage-image" />
          </div>
       </div>
      </div>
      <div>
        <img
          src={curveImage}
          alt="Curve Background" className="curve_image"
          style={{ width: "100%", height: "auto", display: "block", }}
        />
      </div>

      <div className="hero-demo">
        <a
          href="https://www.youtube.com/watch?v=0ulAJS1P3rI&t=71s"
          className="watch-demo-link"
          target="_blank"
          rel="noreferrer"
        >
          Watch Anantya.ai Demo Video →
        </a>

        <div className="container mt-md-3 mt-1 pt-3 ">
          <div className="justify-content-center px-md-5">
            {/* Video Section */}
            <div className="justify-content-center px-md-5 mb-5">
              <div className="video-wrapper">
                {!isPlaying ? (
                  <>
                    <img
                      src={demoVideoThumbnail}
                      alt="Video Thumbnail"
                      className="video-thumbnail"
                    />
                    <div className="helpdesk-video-icon__button" onClick={handlePlay}>
                      <img src={playIcon} className="img-fluid" alt="Play Icon" />
                    </div>
                  </>
                ) : (
                  // <iframe
                  //   className="video-frame"
                  //   src="https://www.youtube.com/embed/0ulAJS1P3rI?autoplay=1"
                  //   title="Demo Video"
                  //   frameBorder="0"
                  //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  //   allowFullScreen
                  // ></iframe>
                  <video className="video-frame" controls autoPlay>
                  <source src={demoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                )}
              </div>
            {/* Buttons */}
              <div className="btns text-center mt-3">
                <a
                  href="https://www.youtube.com/@Anantyaai"
                  className="btn text-white linear_gradiant me-3 rounded-pill" target="_blank"
                  rel="noreferrer"
                >
                  Watch More
                </a>
                <button onClick={scrollToForm} className="btn btn-trans rounded-pill">Book a Demo </button>
              </div>
            </div>
          </div>
        </div>
   </div>
    </>
  );
};

export default HeroSection;
