import React from "react";
import "./Footer.css";
import { getImage } from '../../utils/getImage';  // Correct path to utility
const facebook = getImage('fb.png');
const instagram = getImage('insta.jpg');
const linkedin = getImage('linkdin.png');
const youtube = getImage('youtube.webp');
const logo = getImage('custom-logo.png');
const footercurveImage = getImage("/whatsapp-pricing/footer-curve.png");

function Footer() {
  return (

    <footer className="style-11 custom-footer" style={{ background: "rgba(7, 37, 65, 1)!important" }}>
      <div>
        <img
          src={footercurveImage}
          alt="Curve Background"
          style={{ width: "100%", height: "auto", display: "block" }}
        />
      </div>
      <div className="container">
        {/* Footer Links */}
        <div className="foot-links">
          {/* Lower row */}
          <div className="row text-md-start pt-5 text-white">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-logo">
                <a href="/">
                  <img
                    src={logo}
                    className="img-fluid w-75"
                    alt="footer-logo"
                  />
                </a>
                <div className="links-item ps-md-3">
                  <ul>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/affiliate-program">Become an Affiliate</a></li>
                    <li><a href="/partner-with-us">Become a Partner</a></li>
                  </ul>
                  <p>Follow us:</p>
                </div>
              </div>


              <div className="col-md-3 social-icons text-md-end d-flex text-center d-none">

                <a href="https://www.facebook.com/anantyaai" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="Facebook" className="social-icon" />
                </a>
                <a href="https://www.instagram.com/anantya.ai/" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} alt="Instagram" className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/anantya-ai/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="LinkedIn" className="social-icon" />
                </a>
                <a href="https://www.youtube.com/@Anantyaai" target="_blank" rel="noopener noreferrer">
                  <img src={youtube} alt="YouTube" className="social-icon" />
                </a>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-6 social-icons d-flex">
                <a
                  href="https://www.facebook.com/anantyaai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-link"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/anantya.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-link"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/anantya-ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-link"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://www.youtube.com/@Anantyaai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-link"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>

            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="links-item">
                <h6>Industry</h6>
                <ul>
                  <li><a href="/ecommerce-industry">Ecommerce & Retail</a></li>
                  <li><a href="/healthcare-industry">Healthcare</a></li>
                  <li><a href="/edtech-industry">Ed-Tech</a></li>
                  <li><a href="/banking-industry">Banking & BFSI</a></li>
                  <li><a href="/media-entertainment-industry">Media & Entertainment</a></li>
                  <li><a href="/food-and-beverage-industry">Food & Beverage</a></li>
                  <li><a href="/travel-and-hospitality-industry">Travel & Hospitality</a></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="links-item">
                <h6>Resources</h6>
                <ul>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/knowledge-center">Knowledge Center</a>
                  </li>
                  <li>
                    <a href="/campaign-request-demo">Get Started</a>
                  </li>
                  <li>
                    <a href="/work-with-us">Work with Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="links-item">
                <h6>Address</h6>
                <ul>
                  <li>
                  Plaza-2, Unit No. 3039 & 3040, 
                  Central Square Complex, Bara Hindu Rao Delhi-110006
                  </li>
                  <li>
                    <a href="tel:+917987886387">+91-9899574174</a>
                  </li>
                  <li>
                    <a href="mailto:info@anantya.ai">info@anantya.ai</a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>

        {/* Footer line */}
        <div className="footerline">
          <div className="row justify-content-between align-items-center py-4 ">
            <div className="col-md-12  text-center my-auto ">
              <span>
                Copyright © 2025{" "}
                <a href="/" className="text-white">anantya.ai</a> Inc. All rights reserved
              </span>
            </div>


          </div>
        </div>


      </div>
    </footer>
  );
}

export default Footer;
