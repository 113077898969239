import React, { useState } from "react";
import { getImage } from "../../utils/getImage";
import "./TabVideoSection.css";

// Importing Local Videos
import demoVideo from "../../assets/videos/demo-video.mp4";
import salesVideo from "../../assets/videos/sales.mp4";
import supportVideo from "../../assets/videos/support.mp4";

// Importing Icons & Thumbnails
const tabIcon1 = getImage("/icons/Megaphone.png");
const tabIcon2 = getImage("/icons/Analytics.png");
const tabIcon3 = getImage("/icons/Support-icon.png");
const playIcon = getImage("/whatsapp-pricing/play_icon.svg");
const demoVideoThumbnail = getImage("/whatsapp-pricing/demo-video.png");
const demoVideoThumbnail2 = getImage("/whatsapp-pricing/thumblain.png");
const demoVideoThumbnail3 = getImage("/whatsapp-pricing/thumblains3.png");
const scrollToForm = () => {
    const formElement = document.getElementById("myStickyForm");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

const TabVideoSection = () => {
    const [activeTab, setActiveTab] = useState("Marketing");
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => setIsPlaying(true);

    // Mapping Tabs with Local Video Paths
    const tabData = {
        Marketing: {
            demoVideo: demoVideo,
            demoVideoThumbnail: demoVideoThumbnail,
        },
        Sales: {
            demoVideo: salesVideo,
            demoVideoThumbnail: demoVideoThumbnail2,
        },
        Support: {
            demoVideo: supportVideo,
            demoVideoThumbnail: demoVideoThumbnail3,
        },
    };

    return (
        <div className="container mt-md-5 mt-1 pt-3">
            <h6 className="heading_color">
                MERGE MULTIPLE CHANNELS INTO ONE PLATFORM
            </h6>
            <h2 className="heading_Color_2">
                Engage customers your way with an AI-powered <br />
                omnichannel platform
            </h2>

            {/* Tabs */}
            <div className="d-flex justify-content-center mb-3 TabVideoSection px-3">
                {[
                    { name: "Marketing", icon: tabIcon1 },
                    { name: "Sales", icon: tabIcon2 },
                    { name: "Support", icon: tabIcon3 },
                ].map((tab) => (
                    <button
                        key={tab.name}
                        className={`btn mx-2 ${activeTab === tab.name ? "active-tab" : ""}`}
                        onClick={() => {
                            setActiveTab(tab.name);
                            setIsPlaying(false);
                        }}
                    >
                        <img src={tab.icon} alt={tab.name} style={{ width: "20px", marginRight: "8px" }} />
                        {tab.name}
                    </button>
                ))}
            </div>

            {/* Video Section */}
            <div className="justify-content-center px-md-5 mb-5">
                <div className="video-wrapper">
                    {!isPlaying ? (
                        <>
                            <img
                                src={tabData[activeTab].demoVideoThumbnail}
                                alt={`${activeTab} Demo`}
                                className="video-thumbnail"
                            />
                            <div className="helpdesk-video-icon__button" onClick={handlePlay}>
                                <img src={playIcon} className="img-fluid" alt="Play Icon" />
                            </div>
                        </>
                    ) : (
                        <video className="video-frame" controls autoPlay>
                            <source src={tabData[activeTab].demoVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </div>

                {/* Buttons */}
                <div className="btns text-center mt-3">
                    <a
                        href="https://www.youtube.com/@Anantyaai"
                        className="btn text-white linear_gradiant me-3 rounded-pill" target="_blank"
                        rel="noreferrer"
                    >
                        Watch More
                    </a>
                  <button className="btn btn-trans rounded-pill" onClick={scrollToForm}> Book a Demo </button>
                </div>
            </div>
        </div>
    );
};

export default TabVideoSection;
