import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './IntegrationsSection.css';
import { getImage } from '../../utils/getImage';  // Correct path to utility

// Fetching images dynamically using getImage function
const images = [
  getImage('/whatsapp-pricing/integration/int-12.png'),
  getImage('/whatsapp-pricing/integration/int-11.png'),
  getImage('/whatsapp-pricing/integration/int-13.png'),
  getImage('/whatsapp-pricing/integration/int-10.png'),
  getImage('/whatsapp-pricing/integration/int-9.png'),
  getImage('/whatsapp-pricing/integration/int-7.png'), 
  getImage('/whatsapp-pricing/integration/int-8.png'),
  getImage('/whatsapp-pricing/integration/int-6.png'),
  getImage('/whatsapp-pricing/integration/int-5.png'),
  getImage('/whatsapp-pricing/integration/int-4.png'),
  getImage('/whatsapp-pricing/integration/int-3.png'),
  getImage('/whatsapp-pricing/integration/int-2.png'),
  getImage('/whatsapp-pricing/integration/int-1.png')
];
const IntegrationsSection = () => {
  const boxes = Array.from({ length: 60 }, (_, index) => index + 1);

  return (
    <section className="py-5 mb-5 IntegrationsSection d-none d-md-block">
      <Container>

        {/* Row 1 (Full 12 Columns) */}
       {/* Row 1 (Full 12 Columns) */}
<Row className="mb-3">
  {boxes.slice(0, 12).map((box, index) => (
    <Col key={index} xs={1}>
      <div className="integration-box">
        {(index === 3 || index === 7) && ( 
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>


        {/* Row 2 (Full 12 Columns) */}
       {/* Row 2 (Full 12 Columns) */}
<Row className="mb-3">
  {boxes.slice(12, 24).map((box, index) => (
    <Col key={index} xs={1}>
      <div className="integration-box">
        {(index === 0 || index === 5 || index === 9) && (
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>


        {/* Row 3 - Left & Right 3 Boxes */}
        <Row className="mb-3">
          <Col xs={3}>
          <Row className="justify-content-center">
  {boxes.slice(24, 27).map((box, index) => (
    <Col key={index} xs={4}>
      <div className="integration-box">
        {index === 2 && (
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>

          </Col>

          <Col xs={6} className="d-flex align-items-center justify-content-center">
            <div className="content-box text-center">
              <h2>Seamless Integration<br />with your entire tech stack</h2>

            </div>
          </Col>

          <Col xs={3}>
          <Row className="justify-content-center">
  {boxes.slice(27, 30).map((box, index) => (
    <Col key={index} xs={4}>
      <div className="integration-box">
        {index === 2 && (
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>

          </Col>
        </Row>

        {/* Row 4 - Left & Right 3 Boxes with Center Content */}
        <Row className="mb-3">
          <Col xs={3}>
            <Row className="justify-content-center">
              {boxes.slice(30, 33).map((box, index) => (
                <Col key={index} xs={4}>
                  <div className="integration-box"></div>
                </Col>
              ))}
            </Row>
          </Col>

          <Col xs={6} className="d-flex  justify-content-center ">
            <div className="content-box text-center">
              <a
                href="/integration"
                className="btn btn-grad"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>See all Integration</u>
              </a>
            </div>
          </Col>

          <Col xs={3}>
          <Row className="justify-content-center">
  {boxes.slice(33, 36).map((box, index) => (
    <Col key={index} xs={4}>
      <div className="integration-box">
        {index === 1 && (
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>

          </Col>
        </Row>

        {/* Row 5 (Full 12 Columns) */}
        <Row className="mb-3">
  {boxes.slice(36, 48).map((box, index) => (
    <Col key={index} xs={1}>
      <div className="integration-box">
        {(index === 1 || index === 3 || index === 8) && (
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>


        {/* Row 6 (Full 12 Columns) */}
      {/* Row 6 (Full 12 Columns) */}
<Row className="mt-3">
  {boxes.slice(48, 60).map((box, index) => (
    <Col key={index} xs={1}>
      <div className="integration-box">
        {(index === 6 || index === 11) && (
          <img src={images[index % images.length]} alt="Integration" className="integration-image" />
        )}
      </div>
    </Col>
  ))}
</Row>


      </Container>
    </section>
  );
};

export default IntegrationsSection;
