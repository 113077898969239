import React, { useState, useEffect } from "react";
import "./UniquePopup.css";
import { getImage } from '../../utils/getImage';  // Correct path to utility
// Fetching images dynamically using getImage function
const banerImg = getImage('offer.png');
const UniquePopup = () => {
    const [isOpen, setIsOpen] = useState(false); // Initially closed

    useEffect(() => {
        // Automatically open the popup 10 seconds after the component mounts
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 10000);

        // Cleanup the timer when the component unmounts
        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        isOpen && (
            <div className="unique-popup">
                <div className="unique-popup__backdrop" onClick={handleClose}></div>
                <div className="unique-popup__container animated-gradient  px-4 py-4 row">


                    <div className="unique-popup__content text-start  col-lg-6 col-md-6 col-sm-12 col-12">
                        <h2>Trusted by 100+ brands across globe
                        </h2>
                        <p>Get ₹500 OFF on all Self Serve Plans!</p>
                      <h6>Your dream plan is ready-ARE YOU?
                        </h6>
                        <a href="/whatsapp-pricing" target="_blank" rel="noopener noreferrer">
                            <button className="unique-popup__button">Claim Offer</button>
                        </a>
                    </div>
                    <div className="unique-popup__image-container col-lg-6 col-md-6 col-sm-12 col-12">

                        <svg
                            className="unique-popup__close-icon"
                            onClick={handleClose}
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                        >
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>   <img
                            title="banner_image"
                            alt="banner_image"
                            src={banerImg}
                            className="unique-popup__image"
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default UniquePopup;
