import React, { useState, useEffect } from 'react';
import "./DIYPopup.css";
import GetStartedForm from "./GetStartedForm";
import PackageSelection from "./PackageSelection";
import Addon from "./Addon";
import AgreementStep from "./AgreementStep";
import CompletionStep from "./CompletionStep";
import { apiBaseurl } from '../../../utils/constant';
import { getImage } from "../../../utils/getImage"; // Adjusted path
const skip = getImage("skip.png");
const DIYPopup = ({ isOpen, onClose, packName, plan }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [packagePrice, setPackagePrice] = useState(null);
  const [formData, setFormData] = useState({}); // Store user data
  const [error, setError] = useState(null); // Define setError
  const [showConfirmation, setShowConfirmation] = useState(false);

  console.log(packName, plan);
  useEffect(() => {
    if (isOpen) {
      // Set zoom to 90% when the popup opens
      document.body.style.zoom = "90%";
    } else {
      // Reset zoom to 100% when the popup closes
      document.body.style.zoom = "100%";
    }
    // Clean up on component unmount
    return () => {
      document.body.style.zoom = "100%";
    };
  }, [isOpen]);

  useEffect(() => {
    if (currentStep === 2) {
      console.log("Step 2 reached. Sending WhatsApp message...");
      sendWhatsAppMessage("96");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  if (!isOpen) return null;

  const handleFormSubmit = (data) => {
    setFormData(data);
  };
  const sendWhatsAppMessage = async (templateId) => {
    if (!formData.mobile) return
    const recipientNumber = formData.mobile
    // const templateId = templateId;
    const parameters = [
      { key: "step", value: "2" },
    ];

    console.log("Step 1: Preparing to send WhatsApp message from frontend.");
    console.log("Recipient Number:", recipientNumber);
    console.log("Template ID:", templateId);
    console.log("Parameters:", parameters);

    try {
      console.log("Step 2: Sending POST request to backend API.");

      const requestBody = {
        recipientNumber,
        templateId,
        parameters,
      };

      console.log("Request Body:", requestBody);

      // const response = await fetch("http://localhost:5000/api/whatsapp/send-message", {
      const response = await fetch(`${apiBaseurl}whatsapp/send-message`, {

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      console.log("Step 3: Received response from backend API.");
      console.log("Response Status:", response.status);

      if (response.ok) {
        console.log("Step 4: Parsing successful response.");
        const data = await response.json();
        console.log("WhatsApp message sent successfully!", data);
        setError(""); // Clear any previous error
      } else {
        console.log("Step 5: Backend API returned an error.");
        const errorData = await response.json();
        console.error("Error Details from Backend API:", errorData);
        throw new Error(errorData.message || "Failed to send WhatsApp message");
      }
    } catch (error) {
      console.error("Step 6: Error occurred in sendWhatsAppMessage function.");
      console.error("Error Details:", error.message || error);

      setError(error.message || "An unexpected error occurred");
    }
  };


  // Trigger email when payment is successful
  const handlePaymentSuccess = async () => {
    setCurrentStep(5); // Go to CompletionStep

    const userEmail = formData.email || JSON.parse(localStorage.getItem("userData"))?.email;
    const userName = formData.name || JSON.parse(localStorage.getItem("userData"))?.name;

    if (!userEmail) {
      console.error("Email not found!");
      return;
    }

    try {
      // Send a POST request to the backend API
      // const response = await fetch('http://localhost:5000/api/send-payment-confirmation', {
      const response = await fetch(`${apiBaseurl}send-payment-confirmation`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientEmail: userEmail,  // Get email from formData or localStorage
          clientName: userName,    // Get name from formData or localStorage
        }),
      });

      if (response.ok) {
        console.log("Payment confirmation email sent successfully!");
      } else {
        console.error("Failed to send payment confirmation email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // Back Button Functionality
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  // popup
  const handleClose = () => {
    setShowConfirmation(true);
  };

  const confirmClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  const cancelClose = () => {
    setShowConfirmation(false);
  };
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <GetStartedForm handleFormSubmit={handleFormSubmit} onNext={() => setCurrentStep(2)} />;
      case 2:
        return (
          <PackageSelection
            onNext={(price) => {
              setPackagePrice(price);
              setCurrentStep(3);
            }}
          />
        );
      case 3:
        return <Addon onNext={() => setCurrentStep(4)} initialPackagePrice={packagePrice} packName={packName} />;
      case 4:
        return <AgreementStep formData={formData} onPaymentSuccess={handlePaymentSuccess} setCurrentStep={setCurrentStep} />;
      case 5:
        return <CompletionStep sendWhatsAppMessage={templateId => sendWhatsAppMessage(templateId)} onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content "
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        {/* <button className="close-btn" onClick={onClose}>
          &times;
        </button> */}
        <button className="close-btn" onClick={handleClose}>&times;</button>
        {showConfirmation && (
          <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content position-relative" style={{ border: '3px solid #22C9B7', borderRadius: '25px', padding: '20px' }}>
  
  {/* Close Button at Top Right */}
  <button
    type="button"
    className="btn-close position-absolute"
    style={{ top: '10px', right: '10px' }}
    onClick={cancelClose}
  ></button>

  {/* Modal Header */}
  <div className="modal-header border-0 justify-content-center">
    <div className="d-flex flex-column align-items-center text-center">
      <img
        src={skip}
        className="img-fluid"
        alt="skip"
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <h5 className="modal-title mt-2">Are you sure you want to exit?</h5>
    </div>
  </div>

  {/* Modal Footer */}
  <div className="modal-footer border-0 justify-content-center">
    <button className="btn skip_button_yes me-3" onClick={confirmClose}>Yes</button>
    <button className="btn skip_button_no" onClick={cancelClose}>No</button>
  </div>

</div>

            </div>
          </div>
        )}

        {/* Progress Bar */}
        {/* Progress Bar */}
        <div className="unique-progress-container">
          {[
            { step: 1, label: "Details" },
            { step: 2, label: "Package" },
            { step: 3, label: "Add-ons" },
            { step: 4, label: "Agreement" },
            { step: 5, label: "Payments" },
          ].map((item, index) => (
            <div key={item.step} className="progress-item">
              {/* Circle */}
              <div
                className={`progress-circle ${item.step <= currentStep ? "completed" : ""}`}
              >
                {item.step < currentStep ? (
                  <span className="tick-icon"> <i className="fas fa-check tick-icon"></i></span>
                ) : (
                  item.step
                )}
              </div>

              {/* Label */}
              <div className="progress-label">{item.label}</div>

              {/* Line (exclude after the last circle) */}
              {index !== 4 && (
                <div
                  className={`progress-line ${item.step < currentStep ? "completed" : ""}`}
                ></div>
              )}
            </div>
          ))}
        </div>


        {/* Dynamic Content Section */}
        <div className="content-section footerline">
          {/* Back Button */}
          {currentStep > 1 && (
            <button
              className="btn  position-absolute bottom-0 start-0 m-3 ms-5   btn-graident-pay  btn-lg"
              style={{
                zIndex: 10,
              }}
              onClick={handleBack}
            >
              Back
            </button>
          )}
          {renderStepContent()}
        </div>
      </div>
    </div>
  );
};

export default DIYPopup;

