import React, { useEffect, useState } from "react";
import { getCountryByIP } from "../utils/ipLocation";
import { Helmet } from "react-helmet";
import "./whatsapppricing.css"; // Optional: Add custom CSS file for styling
import TabSection from "../components/common/TabSection";
import ScrollToTopButton from "../components/common/ScrollToTopButton";
import WhatsAppWidget from "../components/common/WhatsAppWidget";
import LogoCarousel2 from "../components/common/LogoCarousel2";
import HeroSection from "../components/common/HeroSection";
import Reasons from "../components/common/Reasons";
// import CountUp from "react-countup";
import YoutubeVideoSlider from "../components/common/YoutubeVideoSlider";
import { getImage } from "../utils/getImage"; // Adjust the path as <needed></needed>
import DIYPopup from '../components/common/DIYProcess/DIYPopup';
import Footer from "../components/common/Footer";
import ImageGrid from "../components/common/ImageGrid"; // Adjust the path as per your folder structure
import ReCAPTCHA from "react-google-recaptcha";
const logo = getImage('custom-logo.png');
const checkIcon = getImage("Check-icon.svg");
const check2Icon = getImage("/whatsapp-pricing/blue-tick.png");
const trainingIcon = getImage("/whatsapp-pricing/training.png");
const addonIcon = getImage("/addon.png");

const cardImg1 = getImage('/whatsapp-pricing/card-1.png');
const cardImg2 = getImage('/whatsapp-pricing/scaner-whatsapp.png');
const cardImg3 = getImage('/whatsapp-pricing/card-3.png');
const cardImg4 = getImage('/whatsapp-pricing/card-4.png');
const cardImg5 = getImage('/icons/6.png');
const cardImg6 = getImage('/icons/1.png');
const cardImg7 = getImage('/icons/3.png');
const cardImg8 = getImage('/icons/2.png');
const cardImg9 = getImage('/icons/4.png');
const cardImg10 = getImage('/icons/5.png');


const WhatsAppPricing = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  // Handle reCAPTCHA verification
  const handleRecaptcha = (value) => {
    if (value) {
      setCaptchaVerified(true);
    }
  };
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showIndianPricing, setShowIndianPricing] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [packageName, setPackageName] = useState({
    packName: "",
    plan: ""
  })

  console.log((packageName));

  // Handle the package selection
  const handleDIYProcess = (packageId, packName, plan) => {
    // debugger;  
    localStorage.setItem("packageSelected", packageId);
    console.log("Clicked Package ID:", packageId);
    if (selectedPackage !== packageId) {
      setSelectedPackage(packageId);
      setIsPopupOpen(true); // Open the popup
      setPackageName({ ...packageName, packName, plan })
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Popup ko close karega
    setSelectedPackage(null);
    setPackageName({})
  };

  useEffect(() => {
    const fetchCountry = async () => {
      const country = await getCountryByIP();
      if (country === "IN") {
        setShowIndianPricing(true);
      }
    };

    fetchCountry();
  }, []);



  if (!showIndianPricing) {
    return null;
  }

  const showContent = (contentId) => {
    document.getElementById("monthly-content").style.display =
      contentId === "monthly-content" ? "block" : "none";
    document.getElementById("yearly-content").style.display =
      contentId === "yearly-content" ? "block" : "none";
  };

  const scrollToForm = () => {
    const formElement = document.getElementById("myStickyForm");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <Helmet>
        <link rel="alternate" hrefLang="x-default" href="https://anantya.ai/whatsapp-pricing" />
        <link rel="alternate" hreflang="en-ae" href="https://anantya.ai/whatsapp-pricing" />
        <link rel="alternate" hreflang="en-sa" href="https://anantya.ai/whatsapp-pricing" />
        <link rel="alternate" hreflang="en-bh" href="https://anantya.ai/whatsapp-pricing" />
        <link rel="alternate" hreflang="en-in" href="https://anantya.ai/whatsapp-pricing" />
        <link rel="alternate" hreflang="en" href="https://anantya.ai/whatsapp-pricing" />

        <title>WhatsApp API Pricing in India | Affordable Business Solutions</title>
        <meta name="robots" content="index,follow" />
        <meta name="title" content="WhatsApp API Pricing in India | Affordable Business Solutions" />
        <meta name="description" content="Affordable WhatsApp pricing plans in India by Anantya.aI. Get the best rates for WhatsApp Business API, chatbots, and more to enhance customer communication." />
        <meta name="keywords" content="whatsapp api pricing, whatsapp business api pricing, whatsapp business pricing, whatsapp business api cost, pricing for whatsapp business api, whatsapp for business pricing, whatsapp api pricing india, whatsapp api cost, whatsapp business cost, whatsapp business api price, whatsapp api charges, whatsapp business api pricing india, pricing for whatsapp business api, whatsapp pricing api" />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_IN" />
        <meta property="og:title" content="WhatsApp API Pricing in India | Affordable Business Solutions" />
        <meta property="og:description" content="Affordable WhatsApp pricing plans in India by Anantya.aI. Get the best rates for WhatsApp Business API, chatbots, and more to enhance customer communication." />
        <meta property="og:url" content="https://anantya.ai/whatsapp-pricing" />
        <meta property="og:site_name" content="anantya.ai" />
        <meta property="og:image" content="https://ik.imagekit.io/cloy701fl/images/partner-program-homepage.webp" />
        <link rel="canonical" href="https://anantya.ai/whatsapp-pricing" />
        <meta name="author" content="Anantya" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Anantya.ai",
            "alternateName": "Conversational Engagement Platform for Businesses | Anantya.ai",
            "url": "https://anantya.ai/",
            "logo": "https://ik.imagekit.io/cloy701fl/images/logo.webp",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971565480273",
              "contactType": "sales",
              "areaServed": ["AE", "SA", "BH", "IN"],
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/anantyaai",
              "https://www.instagram.com/anantya.ai",
              "https://www.youtube.com/@Anantyaai",
              "https://www.linkedin.com/company/anantya-ai",
              "https://www.pinterest.com/anantyaai"
            ]
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "@id": "#WebPage",
            "url": "https://anantya.ai/whatsapp-pricing",
            "name": "WhatsApp API Pricing in India | Affordable Business Solutions"
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://anantya.ai/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "WhatsApp API Pricing in India | Affordable Business Solutions",
                "item": "https://anantya.ai/whatsapp-pricing"
              }
            ]
          })}
        </script>
      </Helmet>

      <div className="whatsapp-pricing-page py-md-0 pt-0">
        <div className="pricing_page_header">
          <div className="d-flex  align-items-center container logos my-0">
            {/* Use the logo image */}
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                style={{ width: "200px", height: "auto" }}
              />
            </a>

            {/* Use the metaLogo image */}
            <div className="d-flex align-items-center ms-auto">
              <button class=" btn demo_button" onClick={scrollToForm}> Book a Demo </button>
            </div>
          </div>
        </div>

        <HeroSection /> {/* Include your Hero Section */}

        <section>
          <div
            className="statics"
          >
            <div className="container pb-5 ">
              <div className="all-statics ">
                <div className="row no-gutters my-auto py-0 px-3">
                  <div className="col-md-12 col-sm-12 align-items-center my-auto">
                    <h2 className="bg-gradient_headings">
                      Trusted by 100+ brands across industries and regions.
                    </h2>
                    <div className="d-flex flex-wrap align-items-center">
                      <LogoCarousel2 />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="desk-section">
        <div className="featured_pricing mb-5 ">
          <div className=" d-flex ">
            <div className="featured-card">
              <div className="featured-icon"><img src={cardImg5} className="img-fluid" alt="Green Tick Verified" /></div>
              <p className="featured-title">Get Blue Tick <br /> Verification</p>
            </div>
            <div className="featured-card">
              <div className="featured-icon"><img src={cardImg6} className="img-fluid" alt="recurring" /></div>
              <p className="featured-title">
                One time & recurring Campaigns on WhatsApp
              </p>
            </div>
            <div className="featured-card">
              <div className="featured-icon"><img src={cardImg7} className="img-fluid" alt="whatsapp" /></div>
              <p className="featured-title">Official WhatsApp Business API</p>
            </div>
            <div className="featured-card">
              <div className="featured-icon"><img src={cardImg8} className="img-fluid" alt="workflow" /></div>
              <p className="featured-title">Build your own Workflows</p>
            </div>
            <div className="featured-card">
              <div className="featured-icon"><img src={cardImg9} className="img-fluid" alt="security" /></div>
              <p className="featured-title">
                Simplicity for SMBs with Enterprise-grade Scale & Security
              </p>
            </div>
            <div className="featured-card">
              <div className="featured-icon"><img src={cardImg10} className="img-fluid" alt="analytics" /></div>
              <p className="featured-title">Campaign & Agent Analytics</p>
            </div>

          </div>
        </div>
        </section>
        <section class="mobi-section">
        <div className="featured_pricing mb-5">

    <div className="row">
      <div className=" col-12 my-3">
        <div className="featured-card">
          <div className="featured-icon"><img src={cardImg5} className="img-fluid" alt="Green Tick Verified" /></div>
          <p className="featured-title">Get Blue Tick Verification</p>
        </div>
      </div>
      <div className=" col-12 mb-3">
        <div className="featured-card">
          <div className="featured-icon"><img src={cardImg6} className="img-fluid" alt="recurring" /></div>
          <p className="featured-title">One time & recurring Campaigns on WhatsApp</p>
        </div>
      </div>
      <div className=" col-12 mb-3">
        <div className="featured-card">
          <div className="featured-icon"><img src={cardImg7} className="img-fluid" alt="whatsapp" /></div>
          <p className="featured-title">Official WhatsApp Business API</p>
        </div>
      </div>
      <div className=" col-12 mb-3">
        <div className="featured-card">
          <div className="featured-icon"><img src={cardImg8} className="img-fluid" alt="workflow" /></div>
          <p className="featured-title">Build your own Workflows</p>
        </div>
      </div>
      <div className=" col-12 mb-3">
        <div className="featured-card">
          <div className="featured-icon"><img src={cardImg9} className="img-fluid" alt="security" /></div>
          <p className="featured-title">Simplicity for SMBs with Enterprise-grade Scale & Security</p>
        </div>
      </div>
      <div className=" col-12 mb-3">
        <div className="featured-card">
          <div className="featured-icon"><img src={cardImg10} className="img-fluid" alt="analytics" /></div>
          <p className="featured-title">Campaign & Agent Analytics</p>
        </div>
      </div>
    </div>

</div>

      </section>


        <section className="banner_section container text-start last-section">
          <div className=" wp-pricing-img pb-5 row ">

            <section className="numbers style-6 mt-70 col-lg-6 col-sm-12  px-md-0 px-5">
              <h2 className=" px-md-0 px-3 text-center heading-gradient mt-0">
                Transform the Way You <br /> Connect and Grow
              </h2>
              <p className="text-center">Seamless tools to boost engagement, enhance <br /> branding, and drive growth</p>

              <div className="content pb-100 brd-gray">
                <div className="row ">
                  <div className="col-lg-6 mb-md-0 mb-3">
                    <div className="card_price_1  style-6 text-black h-100">
                      <h5>INTERACTIVE WHATSAPP WIDGET</h5>
                      <div className="card-title px-3">Seamless tools to boost engagement, enhance branding, and drive
                        growth</div>
                      <img src={cardImg1} className="img-fluid   card_image_Connect" alt="cardImg1" />   <br />
                      <a type="button" href="/whatsapp-widget-generator" className="get-widget-btn" target="_blank" rel="noopener noreferrer"> Get Widget </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card_price_2 style-6 h-100">
                      <h5>SMART ACCESS via QR</h5>
                      <div className="card-title px-3">Enable seamless customer connections with scannable QR codes
                        for chats</div>
                      <img src={cardImg2} className="img-fluid  card_image_Connect" alt="cardImg2" />  <br />
                      <a type="button" href="/generate-whatsapp-link" className="get-widget-btn" target="_blank" rel="noopener noreferrer"> Generate QR </a>

                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className="card_price_3 style-6 h-100">
                      <h5>YOUR BRAND, YOUR IDENTITY</h5>
                      <div className="card-title px-3">With our white label solution offer personalized solutions under your own brand identity</div>
                      <img src={cardImg3} className="img-fluid card_image_Connect" alt="cardImg3" /> <br />
                      <a type="button" href="/white-label-partner" className="get-widget-btn" target="_blank" rel="noopener noreferrer"> Explore Now </a>

                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className="card_price_4 style-6  h-100">
                      <h5>EXPAND WITH PARTNERSHIP</h5>
                      <div className="card-title px-4">Collaborate to unlock growth with strategic business partnerships</div>
                      <img src={cardImg4} className="img-fluid  card_image_Connect" alt="cardImg4" /> <br />
                      <a type="button" href="/partner-with-us" className="get-widget-btn" target="_blank" rel="noopener noreferrer"> Join Us </a>

                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="col-lg-6 col-md-6 col-sm-12 pt-md-0 pt-5 my-auto ps-md-5 px-md-2 px-4" id="myStickyForm">
              <div
                className="py-4 px-md-4 px-4 text-center"
                style={{
                  border: "2px solid #18AE80",
                  borderTopRightRadius: "40px",
                  borderTopLeftRadius: "40px",
                  background: "#fff",
                  boxShadow: "0 34px 54px 0 rgba(0, 0, 0, .1)",
                  overflow: "hidden",
                  borderRadius: "30px",
                }}
              >
                <h2 className="h3 text-center mb-0 bg-gradient_heading">To Book 1:1 Consultation</h2>
                <span>Please use your Business Email</span>
                <form
                  action="https://formspree.io/f/mdorezev"
                  method="POST"
                  className="form-containers pt-3"
                >
                  <h5 className="text-start my-2">Contact Details</h5>
                  <div className="form-row">
                    {/* First Name */}
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="First Name"
                      required
                      onInput={(e) => {
                        const nameRegex = /^[A-Za-z ]{2,30}$/; // Allow only alphabets and spaces
                        // Validate input and add/remove "is-invalid" class
                        e.target.value = e.target.value.replace(/[^A-Za-z ]/g, ''); // Remove any non-alphabet character
                        e.target.classList.toggle("is-invalid", !nameRegex.test(e.target.value));
                      }}
                    />

                    {/* Last Name */}
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      required
                      onInput={(e) => {
                        const nameRegex = /^[A-Za-z ]{2,30}$/; // Allow only alphabets and spaces
                        // Validate input and add/remove "is-invalid" class
                        e.target.value = e.target.value.replace(/[^A-Za-z ]/g, ''); // Remove any non-alphabet character
                        e.target.classList.toggle("is-invalid", !nameRegex.test(e.target.value));
                      }}
                    />
                  </div>

                  <div className="form-row">
                    <div className="phone-container">
                      <select name="countryCode" className="country-code">
                        <option value="+91">+91</option>
                      </select>
                      <input
                        type="text"
                        name="phone"
                        className="form-control ms-md-1"
                        placeholder="WhatsApp Number"
                        onInput={(e) => {
                          // Remove non-digit characters and ensure the length is <= 10
                          const value = e.target.value.replace(/\D/g, '').slice(0, 10); // Slice ensures a maximum of 10 digits
                          e.target.value = value;
                        }}
                      />
                    </div>


                    <input
                      type="email"
                      name="email"
                      className="form-control ms-md-3"
                      placeholder="Business Email" required
                    />
                  </div>

                  <h5 className="text-start mb-2">Company Details</h5>
                  <div className="form-row">
                    <input type="text" name="companyName" className="form-control" required placeholder="Company Name" />
                    <input type="text" name="website" className="form-control" placeholder="Website" />
                  </div>
                  <div className="form-row">
                    <input type="text" name="industry" className="form-control" placeholder="Industry" />
                    <input
                      type="datetime-local"
                      name="socialMedia"
                      className="form-control"
                      placeholder="Select Date and Time"
                    />

                  </div>

                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      id="updates"
                      name="updates"
                    />
                    <label
                      className="form-check-label mt-2"
                      htmlFor="updates"
                      style={{ fontSize: "11px", textAlign: "left" }}
                      required>
                      Get Updates regarding your anantya.ai account on WhatsApp
                    </label>
                  </div>
                  <div className="recaptcha-container text-start mt-3">

                    <ReCAPTCHA
                      sitekey="6LdvVNIqAAAAAHw2v3cyzKFQbJ7-mYzlBeFz1Ex1"
                      onChange={handleRecaptcha}
                    />
                  </div>

                  {/* <div className="recaptcha-box">

                      <input
                        className="form-check-input me-3"
                        type="checkbox"
                        id="terms"
                        name="terms"
                        required
                      />
                      <label
                        className="form-check-label mt-2"
                        htmlFor="terms"
                        style={{ fontSize: "12px", textAlign: "left" }}
                      >
                        I'm not a robot
                      </label>
                      
                    </div> */}
                  <div className="form-submit">
                    <input type="submit" className="btn-submit" value="Book a Consultation" disabled={!captchaVerified} />
                  </div>
                </form>
              </div>
            </div>


          </div>
        </section>
        <TabSection />
        <ImageGrid /> {/* Add the ImageGrid section here */}
        <section style={{ backgroundColor: "#F2F9FF" }} id="target-section">
          <div class="container py-3 text-center">
            <header class="pricing">
              <h1 className="fw-bold pt-4 heading-gradient">
                Pick Your Perfect Plan & Unlock the <br /> Magic of Seamless Growth
              </h1>

              <p>Explore our flexible pricing options designed to scale with your success. <br /> Let’s amplify your business potential together</p>
            </header>
          </div>
        </section>

        <section
          className="pricing style-8"
          style={{ backgroundColor: "#F2F9FF" }}>
          <div className="pricing-head ">
            <div className="container">
              <div className="row align-items-center">

                <div className="col-lg-12 ">
                  <div className="pricing-tabsHead text-center">
                    <div className="price-radios">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="monthly-input"
                          value="option1"
                          defaultChecked
                          onClick={() => showContent("monthly-content")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="monthly-input"
                        >
                          Monthly
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="yearly-input"
                          value="option2"
                          onClick={() => showContent("yearly-content")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="yearly-input"
                        >
                          Yearly
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-body" style={{ backgroundColor: "#F2F9FF" }}>
            <div className="container py-5 px-0">
              <div id="monthly-content" className="monthly-content">
                <div className="row row-cols-1 row-cols-md-4 g-3  px-md-0 px-3">
                  <div
                    className="col ">
                    <div className="pricing-card h-100">
                      <div className="pricing-title">
                        <h2>Promo Pack </h2>
                        <small>(Everything you need to get started right away!)</small>
                        <h1> <del> ₹1599/</del>  ₹899</h1>
                        <p>
                          200 Credits Free
                        </p>
                      </div>
                      <div className="price">
                        {/* Button to trigger DIY process */}
                        <button
                          id="899"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(899, "PromoPack-m", "m")}>
                          Buy Now
                        </button>


                        {/* DIY Popup component */}
                        {isPopupOpen && (
                          <DIYPopup
                            isOpen={isPopupOpen}
                            onClose={closePopup} // Close popup function
                            // {...packageName}

                            packName={packageName.packName}
                          />

                        )}

                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Shared Team Inbox</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Multi-Agent Live Chat</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited Broadcasting & Retargeting </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited Smart Labeling </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited Templates</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Agent Transfer & Manager monitoring </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Welcome & OOO Message </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col  second-row"
                    data-wow-delay="0.2s"
                    style={{ visibility: "visible", animationDelay: "0.2s" }}
                  >

                    <div className="pricing-card">
                      <div className="pricing-title">
                        <h2> Starter Pack </h2>
                        <small className="visibility-hidden">(Recommended for Brands with 5 Lakhs User)</small>
                        <h1> <del>₹3499/ </del> ₹1999</h1>
                        <p>
                          250 Credits Free
                        </p>

                      </div>
                      <div className="price">


                        {/* Button to trigger DIY process */}
                        <button id="1999"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(1999, "StarterPack-m", "m")}>
                          Buy Now
                        </button>
                        {/* DIY Popup component */}
                        {/* <DIYPopup isOpen={isPopupOpen} onClose={closePopup} /> */}
                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Everything in Promo Pack
                            </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Smart Audience Segregation  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Campaign Scheduler </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Campaign Click Tracking</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Smart Agent Routing</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Custom Agent Rules</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Chatbot </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Cart & Catalogue </span>
                          </li>
                          <li className="d-flex align-items-start">
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Integrations
                              (Shopify, WOO-Commerce, Google Sheets )
                            </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited WhatsApp Flows</span>
                          </li>
                        </ul>

                      </div>
                    </div>
                  </div>

                  <div
                    className="col  third-row"
                    data-wow-delay="0.4s"
                  >

                    <div className="pricing-card h-100 ">
                      <div className="popular-head text-center">
                        <p className="pt-2 fw-bold text-white">Most Popular</p>
                      </div>
                      <div className="pricing-title">
                        <h2>Growth Pack</h2>
                        <small className="visibility-hidden">(Recommended for Brands with 5 Lakhs User)</small>
                        <h1> <del>₹6399/ </del> ₹3499</h1>
                        <p>
                          300 Credits Free
                        </p>
                      </div>
                      <div className="price">


                        <button id="3499"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(3499, "GrowthPack-m", "m")}>
                          Buy Now
                        </button>
                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Everything in Starter  Pack
                            </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Omni Channel Team Inbox</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Downloadable Reports  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> WhatsApp Payments </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Mini CRM  </span>
                          </li>
                          <li className="d-flex align-items-start">
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Bulk WA Notifications & Campaigns Analytics  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Conversation Analytics  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Agent Analytics  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Automation Analytics  </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col  third-row"
                    data-wow-delay="0.4s"
                  >
                    <div className="pricing-card h-100">
                      <div className="popular-head text-center">
                        <p className="pt-2 fw-bold text-white">Best value</p>
                      </div>
                      <div className="pricing-title">
                        <h2>Advanced Pack</h2>
                        <small>(Recommended for Brands with 5 Lakhs User)</small>

                        <h1> <del>₹21999/ </del> ₹12999</h1>
                        <p>
                          500 Credits Free
                        </p>
                      </div>
                      <div className="price">

                        <button id="12999"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(12999, "AdvancedPack-m", "y")}>
                          Buy Now
                        </button>

                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Everything in Growth Pack </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Meta Price on Conversations </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Dedicated Account Manager  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Webhooks  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> SMS Fallback </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> White Label Custom Domain </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Advanced Analytics </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> AI Chatbot </span>
                          </li>
                        </ul>
                        <a href="https://wa.link/0wphve" className="price-btn my-3" target="_blanlk" rel="noreferrer">
                          Connect with Expert
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="yearly-content"
                className="yearly-content"
                style={{ display: "none" }}
              >
                <div className="row row-cols-1 row-cols-md-4 g-3  px-md-0 px-3">
                  <div
                    className="col ">
                    <div className="pricing-card h-100">
                      <div className="pricing-title">
                        <h2> Promo Pack </h2>
                        <small>(Everything you need to get started right away!)</small>
                        <h1> <del> ₹17,999/</del> ₹9,999 </h1>
                        <p>
                          200 Credits Free
                        </p>

                      </div>
                      <div className="price">

                        {/* Button to trigger DIY process */}
                        <button id="9999"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(9999, "PromoPack-y", "y")} >
                          Buy Now
                        </button>

                        {/* DIY Popup component */}
                        {/* <DIYPopup isOpen={isPopupOpen} onClose={closePopup} /> */}

                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Shared Team Inbox</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Multi-Agent Live Chat</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited Broadcasting & Retargeting
                            </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited Smart Labeling </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited Templates</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Agent Transfer & Manager monitoring </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Welcome & OOO Message </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col  second-row"
                    data-wow-delay="0.2s"
                    style={{ visibility: "visible", animationDelay: "0.2s" }}
                  >

                    <div className="pricing-card">
                      <div className="pricing-title">
                        <h2> Starter Pack </h2>
                        <small className="visibility-hidden">(Recommended for Brands with 5 Lakhs User)</small>
                        <h1> <del> ₹39,999/</del> ₹19,999 </h1>
                        <p>
                          250 Credits Free
                        </p>

                      </div>
                      <div className="price">

                        {/* Button to trigger DIY process */}
                        <button id="19999"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(19999, "StarterPack-y", "y")}>
                          Buy Now
                        </button>

                        {/* DIY Popup component */}
                        <DIYPopup isOpen={isPopupOpen} onClose={closePopup} packName={packageName.packName} />

                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Everything in Promo Pack
                            </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Smart Audience Segregation </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Campaign Scheduler</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Campaign Click Tracking</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Smart Agent Routing</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Custom Agent Rules</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Chatbot</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Cart & Catalogue</span>
                          </li>
                          <li className="d-flex align-items-start">
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>
                              Integrations (Shopify, WOO-Commerce, Google Sheets)
                            </span>
                          </li>

                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Unlimited WhatsApp Flows
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col  third-row"
                    data-wow-delay="0.4s"
                  >
                    <div className="pricing-card h-100">
                      <div className="popular-head">
                        <p className="pt-2 fw-bold">Most Popular </p>
                      </div>
                      <div className="pricing-title">
                        <h2>Growth Pack</h2>
                        <small className="visibility-hidden">(Recommended for Brands with 5 Lakhs User)</small>
                        <h1> <del> ₹63,999/</del> ₹37,999 </h1>
                        <p>
                          300 Credits Free
                        </p>

                      </div>
                      <div className="price">

                        {/* Button to trigger DIY process */}
                        <button id="37999"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(37999, "GrowthPack-y", "y")}>
                          Buy Now
                        </button>
                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span>Everything in Starter Pack</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Omni Channel Team Inbox</span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Downloadable Reports </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> WhatsApp Payments </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Mini CRM  </span>
                          </li>
                          <li className="d-flex align-items-start">
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Bulk WA Notifications & Campaigns Analytics </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Conversation Analytics </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Agent Analytics </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Automation Analytics </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col  third-row"
                    data-wow-delay="0.4s"
                  >
                    <div className="pricing-card h-100">
                      <div className="popular-head">
                        <p className="pt-2 fw-bold">Best Value</p>
                      </div>
                      <div className="pricing-title">
                        <h2>Advanced Pack</h2>
                        <small>(Recommended for Brands with 5 Lakhs User)</small>
                        <h1> <del>₹1,79,999/</del> ₹1,51,999 </h1>
                        <p>
                          500 Credits Free
                        </p>

                      </div>
                      <div className="price">


                        <button id="151999"
                          className="price-btn mb-3"
                          onClick={() => handleDIYProcess(151999, "AdvancedPack-y", "y")}>
                          Buy Now
                        </button>

                      </div>
                      <div className="pricing-info text-left">

                        <ul>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Everything in Growth Pack </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Meta Price on Conversations </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Dedicated Account Manager
                            </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Webhooks  </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> SMS Fallback </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> White Label Custom Domain </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> Advanced Analytics </span>
                          </li>
                          <li>
                            <span className="icon">
                              <img src={checkIcon} alt="Check icon" />
                            </span>
                            <span> AI Chatbot </span>
                          </li>
                        </ul>
                        <a href="https://wa.link/0wphve" className="price-btn mt-3" target="_blank" rel="noreferrer">
                          Connect with Expert
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pass selectedPackage to the PackageSelection component */}
          {/* { selectedPackage && ( 
            <PackageSelection selectedPackage={selectedPackage} setCurrentStep={(currentStep) => { }} />
          )} */}
        </section>

        <section style={{ backgroundColor: "rgba(38, 189, 211, 0.2)" }}>
          <div className="container py-3 text-md-start text-center">
            <h5 className="fw-bold text-center mt-2">
              Don’t miss out on these Add-ons{" "}
              <span>
                <img
                  src={addonIcon}
                  alt="Assisted Onboarding Icon"
                  style={{
                    width: "30px",
                    height: "30px",
                    transform: "rotate(43deg)", // Adjust rotation angle here
                  }}
                />

              </span>
            </h5>
            <div className="row justify-content-center m-3 bg-square gx-0 p-2">
              {/* Card 1 */}
              <div className="col m-3">
                <div
                  className="p-3 d-flex align-items-center"
                  style={{
                    backgroundColor: "rgba(255, 184, 191, 1)",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    className="me-3 d-flex align-items-center justify-content-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "rgba(255, 255, 255, 0.43)",
                      borderRadius: "50%",
                      padding: "9px",
                    }}
                  >
                    <img
                      src={trainingIcon}
                      alt="Assisted Onboarding Icon"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                  <div>
                    <h6>Assisted Onboarding & Training</h6>
                    <p className="mb-0 lighter-text">
                      We offer real-time and personalized assistance to our clients that helps them get started with Anantya seamlessly.
                    </p>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div className="col m-3">
                <div
                  className="p-3 d-flex align-items-center"
                  style={{
                    backgroundColor: "rgba(34, 206, 186, 1)",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    className="me-3 d-flex align-items-center justify-content-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "rgba(255, 255, 255, 0.75)",
                      borderRadius: "50%",
                      padding: "9px",
                    }}
                  >
                    <img
                      src={check2Icon}
                      alt="Blue Tick Assistance Icon"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                  <div>
                    <h6>Blue Tick Verification & Assistance</h6>
                    <p className="mb-0 lighter-text">
                      We offer step-by-step assistance for applying for verification tick for your brand that increases authentication & reliability.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section style={{ backgroundColor: "rgba(231, 241, 215, 1)" }}>
          <YoutubeVideoSlider />
        </section>

        <Reasons />
        <Footer />
        <ScrollToTopButton />
        <WhatsAppWidget />
      </div>
    </>
  );
};

export default WhatsAppPricing;
